import { useState, useEffect } from 'react'
import { IEmailPreference } from '@bonliva-traits/api/types'
import { EmailPreferenceType } from '@bonliva-traits/api/enums'
import useApiState from './useApiState'

type EmailPreferenceSwitch = {
  label: string
  description: string
  isChecked: boolean
}

type IPreferenceTypeToComponentMap = Record<
  EmailPreferenceType,
  EmailPreferenceSwitch
>

export const useEmailPreferences = () => {
  const [emailPreferences, setEmailPreferences] =
    useState<IPreferenceTypeToComponentMap>({
      [EmailPreferenceType.NewBooking]: {
        label: 'Ny bokning',
        description: 'Varje gång du får en ny bokning',
        isChecked: false,
      },
      [EmailPreferenceType.CanceledBooking]: {
        label: 'Avbokning',
        description: 'Varje gång en bokning avbokas',
        isChecked: false,
      },
      [EmailPreferenceType.UpdatedBooking]: {
        label: 'Uppdaterad bokning',
        description: 'Varje gång en bokning uppdateras',
        isChecked: false,
      },
      [EmailPreferenceType.BookingReminderDayBefore]: {
        label: 'Påminnelse',
        description: 'Påminnelse dagen innan ett samtal',
        isChecked: false,
      },
      [EmailPreferenceType.CompletedBookingTreatmentForm]: {
        label: 'Hälsoformulär',
        description: 'Varje gång ett Hälsoformulär är färdigställt',
        isChecked: false,
      },
      [EmailPreferenceType.CompletedTreatmentPlanTreatmentForm]: {
        label: 'Uppföljningsformulär',
        description: 'Varje gång ett Uppföljningsformulär är färdigställt',
        isChecked: false,
      },
      [EmailPreferenceType.AcceptedTreatmentPlan]: {
        label: 'Godkänd behandlingsplan',
        description: 'När en behandlingsplan godkänns',
        isChecked: false,
      },
      [EmailPreferenceType.RejectedTreatmentPlan]: {
        label: 'Avböjd behandlingsplan',
        description: 'När en behandlingsplan avböjs',
        isChecked: false,
      },
    })

  const myPreferences = useApiState<IEmailPreference[]>(
    `v1/email-preferences/me`
  )

  const togglePreferenceHandler = async (
    type: EmailPreferenceType,
    isChecked: boolean
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    myPreferences.put({ data: { type } })
    setEmailPreferences((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        isChecked,
      },
    }))
  }

  const fetchPreferencesHandler = async () => {
    const res = await myPreferences.get()

    res.forEach((preference) => {
      setEmailPreferences((prev) => ({
        ...prev,
        [preference.type]: {
          ...prev[preference.type],
          isChecked: true,
        },
      }))
    })
  }

  useEffect(() => {
    fetchPreferencesHandler()
  }, [])

  return [
    emailPreferences,
    togglePreferenceHandler,
    myPreferences.hasLoaded,
  ] as const
}
