import React, { useCallback, useState, useEffect, ChangeEvent } from 'react'
import * as S from './EditProfileModal.style'
import * as constant from '@bonliva-traits/constants'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Modal from '@bonliva-components/web/shared/Modal'
import Select from '@bonliva-components/web/shared/Select'
import Spinner from '@bonliva-components/web/shared/Spinner'
import {
  IFile,
  IFilter,
  IFilterTag,
  ILanguage,
  IPhysicalLocation,
  ITreaterTag,
} from '@bonliva-traits/api/types'
import {
  useFetchTreaterProfile,
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import { getYear } from 'date-fns'
import { getLastHundredYears } from '@bonliva-traits/utils/getLastHundredYears'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { useApi } from '@bonliva-traits/api'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal/DeleteModal'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const EditProfileModal = (props: Props) => {
  const api = useApi()
  const yearValues = getLastHundredYears()
  const profile = useTreaterProfile()
  const fetchProfile = useFetchTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()

  const treaterFiltersApi = useApiState<IFilter[]>(
    '/v3/swiping/treater/filters'
  )

  const treaterTagsApi = useApiState<ITreaterTag[]>(
    '/v3/swiping/treater/treater-tags'
  )

  useEffect(() => {
    treaterFiltersApi.get()
    treaterTagsApi.get()
  }, [])

  useEffect(() => {
    if (!treaterTagsApi.data) {
      return
    }
    setTreaterTags(treaterTagsApi.data)
  }, [treaterTagsApi.data])

  useEffect(() => {
    if (!treaterFiltersApi.data) {
      return
    }
    const tags: IFilterTag[] = []
    treaterFiltersApi.data.forEach((filter) => {
      filter.tags?.forEach((tag) => {
        tag.title = `${filter.title} - ${tag.title}`
        tags.push(tag)
      })
    })
    setFilterTags(tags)
  }, [treaterFiltersApi.data])

  const [treaterTags, setTreaterTags] = useState<ITreaterTag[]>([])
  const [filterTags, setFilterTags] = useState<IFilterTag[]>([])
  const hiddenFileInputAvatar = React.useRef<HTMLInputElement>(null)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [languages, setLanguages] = useState<ILanguage[]>([])
  const [title, setTitle] = useState(profile.data.title || '')
  const [bio, setBio] = useState(profile.data.bio || '')
  const [email, setEmail] = useState(profile.data.email || '')
  const [licensedAt, setLicensedAt] = useState(profile.data.licensedAt)
  const [physicalLocation, setPhysicalLocation] =
    useState<IPhysicalLocation | null>(profile.data.physicalLocation || null)
  const [tooLongError, setTooLongError] = useState(false)

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      language: 'sv',
      types: ['address'],
    },
    debounce: 300,
  })

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPlacesValue(e.target.value)
  }

  const handleSelect =
    ({ description }: { description: string }) =>
    () => {
      setPlacesValue(description, false)

      clearSuggestions()

      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0])
        setPhysicalLocation({
          adress: description,
          location: {
            type: 'Point',
            coordinates: [lat, lng],
          },
          extraInfo: '',
        })
      })
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const { place_id, description } = suggestion

      return (
        <S.LocationSuggestion key={place_id} onClick={handleSelect(suggestion)}>
          <S.Suggestion>{description}</S.Suggestion>
        </S.LocationSuggestion>
      )
    })

  const avatar = useApiState<IFile>('v1/uploads', profile.data.avatar)

  const handleChangeAvatar = useCallback(() => {
    if (!hiddenFileInputAvatar.current?.files) return
    const formData = new FormData()
    formData.append('file', hiddenFileInputAvatar.current.files[0])
    avatar.post({ data: formData })
  }, [])

  const updateTreaterHandler = useCallback(async () => {
    const body = {
      bio: bio,
      title: title,
      email: email,
      licensedAt: licensedAt,
      avatarId: avatar.data?.id || null,
      languages: languages.map(({ code }) => code),
      physicalLocation: physicalLocation,
    }

    if (physicalLocation === null && profile.data?.physicalLocation?.id) {
      await api.delete(
        `v1/treaters/physical-locations/${profile.data?.physicalLocation?.id}`
      )
    }

    await Promise.all([
      updateProfile(body),
      treaterTagsApi.post({
        data: {
          tags: treaterTags,
        },
      }),
    ])
    fetchProfile()
  }, [
    languages,
    bio,
    title,
    licensedAt,
    email,
    avatar.data,
    physicalLocation,
    treaterTags,
  ])

  const onDismissHandler = useCallback(() => {
    fetchProfile()
    props.setIsOpen(false)
  }, [])

  useEffect(() => {
    const filteredLanguages = constant.languages.filter((l) =>
      profile.data.languages?.includes(l.code)
    )

    setLanguages(filteredLanguages)
    setPlacesValue(profile.data?.physicalLocation?.adress || '', false)
  }, [])

  const deleteLocation = async () => {
    try {
      setPlacesValue('', false)
      setPhysicalLocation(null)
      setOpenDeleteModal(false)
    } catch (error) {
      throw new Error("Couldn't delete physical location")
    }
  }

  useEffect(() => {
    if (bio.length > 1000) {
      setTooLongError(true)
    } else {
      setTooLongError(false)
    }
  }, [bio])

  if (openDeleteModal) {
    return (
      <DeleteModal
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        onDelete={deleteLocation}
        title="Radera mottagning"
        warningTitle="Du har ett aktivt arbetsschema som tillåter fysiska bokningar. Är du säker på att du vill ta bort din mottagningsadress?"
        isLoading={false}
        items={[]}
      />
    )
  }

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={onDismissHandler}>
        <S.EditProfileModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>Redigera profil</S.Title>
          </S.Header>

          <S.Content>
            <S.FlexWrapper>
              <S.ImageWrapper>
                {avatar.data?.url ? (
                  <S.Image src={avatar.data?.url} />
                ) : (
                  <S.NoImage />
                )}

                {avatar.isLoading && (
                  <S.Loading>
                    <Spinner />
                  </S.Loading>
                )}
              </S.ImageWrapper>

              <S.Button onClick={() => hiddenFileInputAvatar.current?.click()}>
                Byt profilbild
              </S.Button>

              <input
                type="file"
                accept="image/*"
                ref={hiddenFileInputAvatar}
                onChange={handleChangeAvatar}
                style={{ display: 'none' }}
              />
            </S.FlexWrapper>

            <span>
              <S.Label>Email</S.Label>
              <S.TextInput
                placeholder="Ange din email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>

            <S.FlexWrapper>
              <S.SelectExperienceWrapper>
                <S.Label>Yrkestitel</S.Label>
                <Select
                  isDisabled
                  value={{ value: title, label: title }}
                  options={constant.titles}
                  onChange={(t) => t && setTitle(t.value)}
                />
              </S.SelectExperienceWrapper>

              <S.SelectExperienceWrapper>
                <S.Label>År för legitimering</S.Label>
                <Select
                  placeholder="Ange år för legitimering"
                  value={
                    licensedAt !== undefined && {
                      value: licensedAt,
                      label: licensedAt
                        ? getYear(new Date(licensedAt)).toString()
                        : '',
                    }
                  }
                  options={yearValues}
                  onChange={(e) => e && setLicensedAt(e.value)}
                />
              </S.SelectExperienceWrapper>
            </S.FlexWrapper>

            <span>
              <S.Label>Språk</S.Label>
              <Select
                isMulti
                options={constant.languages.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
                value={languages}
                getOptionValue={({ code }) => code}
                getOptionLabel={({ name }) => name}
                onChange={(l) => setLanguages(l as ILanguage[])}
              />
            </span>

            <span>
              <S.Label>Biografi</S.Label>
              <S.TextField
                placeholder="Skriv en kort beskrivning av dig själv"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
              {tooLongError && (
                <S.ErrorMessage>
                  Din biografi får inte överstiga 1000 tecken
                </S.ErrorMessage>
              )}
            </span>
            {!physicalLocation && (
              <S.SecondaryButton
                onClick={() =>
                  setPhysicalLocation({
                    adress: '',
                    location: {
                      type: '',
                      coordinates: [0, 0],
                    },
                    extraInfo: '',
                  })
                }
              >
                Lägg till mottagning
              </S.SecondaryButton>
            )}
            {physicalLocation && (
              <S.Group>
                <span>
                  <S.Label>Mottagningsadress</S.Label>
                  <S.LocationSlideSelectWrapper>
                    <S.TextInput
                      style={{ width: '100%' }}
                      value={value}
                      onChange={handleInput}
                      disabled={!ready}
                      placeholder="Skriv in din adress här"
                    />
                    {status === 'OK' && (
                      <S.LocationSuggestionWrapper>
                        {renderSuggestions()}
                      </S.LocationSuggestionWrapper>
                    )}
                  </S.LocationSlideSelectWrapper>
                </span>
                <span>
                  <S.Label>Mottagningsadress - Extra info</S.Label>
                  <S.LocationSlideSelectWrapper>
                    <S.TextInput
                      style={{ width: '100%' }}
                      value={physicalLocation.extraInfo}
                      onChange={(e) =>
                        setPhysicalLocation({
                          ...physicalLocation,
                          extraInfo: e.target.value,
                        })
                      }
                      placeholder="Lägg till extra information (t.ex. våning, portkod)"
                    />
                  </S.LocationSlideSelectWrapper>
                </span>
                <span>
                  <S.DeleteButton onClick={() => setOpenDeleteModal(true)}>
                    Radera mottagning
                  </S.DeleteButton>
                </span>
              </S.Group>
            )}

            <span>
              <S.Label>Timpris</S.Label>
              <S.Body>Kontakta Bonliva Care för justering av timpris</S.Body>
            </span>

            <span>
              <S.Label>Presentationsvideo</S.Label>
              <S.Body>
                Kontakta Bonliva Care för uppladdning av presentations video
              </S.Body>
            </span>

            <span>
              <S.Label>Swipe Booking filters</S.Label>
              <Select
                menuPlacement={'top'}
                isMulti
                options={filterTags}
                value={treaterTags}
                getOptionValue={({ code }) => code}
                getOptionLabel={({ title }) => title}
                onChange={(tags) => setTreaterTags(tags as ITreaterTag[])}
              />
            </span>
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.OutlineButton>Avbryt</S.OutlineButton>
            </Modal.DismissButton>

            <Modal.DismissButton onClick={updateTreaterHandler}>
              <S.Button disabled={tooLongError}>Spara</S.Button>
            </Modal.DismissButton>
          </S.Footer>
        </S.EditProfileModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default EditProfileModal
